import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Grid } from "@material-ui/core"

// import app components
import { Edges, Form, PageIntro, Spacer, Textarea } from "components"

export default function DefaultPageTemplate(props) {
  const {
    data: {
      page: {
        templateForms: {
          formFields: {
            formHero: { image, headline },
            formContent: { main },
            formId,
            formOutro
          }
        }
      }
    }
  } = props

  return (
    <>
      <PageIntro headline={headline} image={image} />

      <ContentContainer>
        <Edges size="cont">
          <Grid container>
            <Grid item md={2} />
            <Grid item md={8}>
              <Spacer mt={80} />
              {main && (
                <Spacer mb={60}>
                  <Textarea content={main} />
                </Spacer>
              )}

              {formId && (
                <Spacer mb={60}>
                  <StyledForm
                    buttonText="Submit"
                    formId={formId}
                    hideTitle={true}
                  />
                </Spacer>
              )}

              {formOutro && (
                <Spacer mb={60}>
                  <Textarea content={formOutro} />
                </Spacer>
              )}
            </Grid>
          </Grid>
        </Edges>
      </ContentContainer>
    </>
  )
}

const ContentContainer = styled.div`
  background: radial-gradient(
    69.12% 69.29% at 12.66% -27.53%,
    #000000 41.89%,
    #252525 100%
  );
  color: white;
`

const StyledForm = styled(Form)`
  form {
    .required {
      display: inline-block;
      margin-left: 3px;
    }

    .form-full-width {
      width: 100%;
    }

    .field--section {
      border-bottom: 1px solid;
      margin: 30px 0px 15px;
      width: 100%;

      label {
        font-size: 22px;
        margin-left: 0px;
      }
    }

    button {
      background: #51eaff;
      color: #000;
      padding: 7px 15px;
      min-width: 250px;

      &:hover {
        background: black;
        color: white;
        box-shadow: inset 0px 0px 0px 1px #51eaff;
      }
    }

    .field--text label,
    .field--textarea label {
      margin-left: 15px;
    }

    input,
    textarea,
    select,
    input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="number"] {
      background: #000;
      border: 1px solid #51eaff;
      border-radius: 15px;
      color: white;
      height: auto;
      font-size: 14px;
      line-height: 20px;
      padding: 5px 15px;
    }
  }
`

export const CollectionQuery = graphql`query FormsTemplate($id: String!) {
  page: wpPage(id: {eq: $id}) {
    templateForms {
      formFields {
        formHero {
          text
          headline
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
          }
        }
        formId
        formContent {
          main
        }
        formOutro
      }
    }
  }
}
`
